import React, { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Image } from '../../../utils/image';

interface TypesSectionProps {
    heading: string;
    subheadingSection1: string;
    subheadingSection2: string;
    descSection1: string;
    descSection2: string;
    imageUrlSection1: string;
    imageUrlSection2: string;
}

const TypesSection = ({
    heading,
    subheadingSection1,
    subheadingSection2,
    descSection1,
    descSection2,
    imageUrlSection1,
    imageUrlSection2,
}: TypesSectionProps): ReactElement => (
    <Row noGutters>
        <Col
            xs="12"
            style={{
                textAlign: `center`,
                paddingTop: `3rem`,
                color: `#2e598f`,
                paddingBottom: `3rem`,
            }}
        >
            <div
                style={{
                    fontSize: `2rem`,
                }}
            >
                {heading}
            </div>
        </Col>
        <Col md="12" lg="6" style={{ textAlign: `center` }}>
            <div style={{ textAlign: `center` }}>
                <Image
                    style={{ height: `4rem`, width: `4rem` }}
                    src={imageUrlSection1}
                />
            </div>
            <div
                style={{
                    fontSize: `1.2rem`,
                    fontWeight: `bold`,
                    paddingTop: `1rem`,
                }}
            >
                {subheadingSection1}
            </div>
            <p
                style={{
                    padding: `1rem`,
                    paddingLeft: `5rem`,
                    paddingRight: `5rem`,
                    color: `#535b6c`,
                }}
            >
                {descSection1}
            </p>
        </Col>
        <Col md="12" lg="6" style={{ textAlign: `center` }}>
            <div style={{ textAlign: `center` }}>
                <Image
                    style={{ height: `4rem`, width: `4rem` }}
                    src={imageUrlSection2}
                />
            </div>
            <div
                style={{
                    fontSize: `1.2rem`,
                    fontWeight: `bold`,
                    paddingTop: `1rem`,
                }}
            >
                {subheadingSection2}
            </div>
            <p
                style={{
                    padding: `1rem`,
                    paddingLeft: `5rem`,
                    paddingRight: `5rem`,
                    color: `#535b6c`,
                }}
            >
                {descSection2}
            </p>
        </Col>
    </Row>
);

export default TypesSection;
