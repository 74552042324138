import React, { ReactElement } from 'react';
import { Container } from 'react-bootstrap';
import { Layout } from '../components/common';
import ContentImageRow from '../components/common/ContentImageRow';
import BannerSection from '../components/common/contentSections/BannerSection';
import ContentHero from '../components/common/contentSections/ContentHero';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import TypesSection from '../components/common/contentSections/TypesSection';
import ImageContentRow from '../components/common/ImageContentRow';
import { ContentData } from '../utils/entities';

const B2BContentData: ContentData[] = [
    {
        id: 1,
        title: `Governance and federation`,
        desc: `Maintain user accounts, attributes and give entitlements
    to partners. Easily connect with partners that have
    their own IDP or access management deployment and enable
    logins from the partner’s IDP.`,
        imageUrl: `images/b2b-governance-federation@3x.png`,
    },
    {
        id: 2,
        title: `Manage ID`,
        desc: `Manage and use organization identities and group users
        by verified organization identity. Automatically
        deprovision users within seconds.`,
        imageUrl: `images/b2b-manage-id@3x.png`,
    },
    {
        id: 3,
        title: `Directory integration`,
        desc: `Connect with customer’s active directory or LDAP and
        import identities from AD/LDAP.`,
        imageUrl: `images/b2b-directory-integration@3x.png`,
    },
];

const B2B = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="B2B Integration"
                desc="StackIAM allows you to integrate with other businesses,
                        enterprise directory and give your business partners
                        access to information they might need."
                buttonText="Try StackIAM"
                imageUrl="/images/b2b.svg"
            />
            <TypesSection
                heading="Integration Types"
                subheadingSection1="Inbound Federation"
                subheadingSection2="Directory Integration"
                descSection1="Easily connect with partners that have their own IDP or
            access management deployment; federate using SAML or
            OIDC."
                descSection2="Connect your customer's LDAP or Active Directory;
            synchronize user accounts to Universal Directory."
                imageUrlSection1="images/rich-text-converter@3x.jpg"
                imageUrlSection2="images/user-shield@3x.jpg"
            />
            <HeadingSection
                heading="Features"
                subheading="B2B Integration"
                desc="Whether you're looking for a two-factor authentication
                        solution to meet compliance targets, or building a full
                        MFA security framework, Okta is the adaptive
                        multi-factor authentication vendor that has you covered.
                        Implement a variety of different factors for
                        authentication across usability and assurance levels:"
            />
            {B2BContentData.map((item) => {
                if (item.id % 2 === 0) {
                    return <ContentImageRow key={item.id} {...item} />;
                } else {
                    return <ImageContentRow key={item.id} {...item} />;
                }
            })}
            <BannerSection />
        </Container>
    </Layout>
);

export default B2B;
